import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import RegisterDone from "@/components/RegisterDone.vue";
import PralineListDoos from "@/views/listViews/PralineListDoos.vue";
import AutomaatRij from "@/components/AutomaatRij.vue";


const routes= [
  {
    path: '/',
    name: 'home',
    component: HomeView,

  },
  {
    path: '/automaat/rij/:id',
    name: 'rijAutomaat',
    component: AutomaatRij,
    props: true
  },
  {
    path: '/pralines',
    name: 'Pralines',
    component: PralineListDoos,
    props: {
      titel: "Pralines",
      text: "Allergenen: bevat amandel, hazelnoot, melk en sojalecithine. \nKan sporen bevatten van gluten, andere noten, ei, gluten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "geschenkdoos",
          beschrijving: "geschenkdoos met 16 pralines",
          image: "pralines/geschenkdoos4.jpg"
        },
        {
          naam: "ballotin",
          beschrijving: "doosje: 250g - 375g - 500g",
          image: "pralines/dozen_wit2b.jpg"
        },
        {
          naam: "Vierkant met bubbels",
          beschrijving: "Aardbeiganache",
          image: "pralines/vierkant_bubbel.jpg"
        },
        {
          naam: "Enveloppe",
          beschrijving: "Amandelpraliné",
          image: "pralines/enveloppe1.jpg"
        },
        {
          naam: "Gestreept vierkant",
          beschrijving: "Chouffe cream karamel\n(bevat alcohol)",
          image: "pralines/vierkant_gestreept.jpg"
        },
        {
          naam: "Margriet",
          beschrijving: "Mango- passievruchtganache",
          image: "pralines/margriet.jpg"
        },
        {
          naam: "Koffieboon",
          beschrijving: "Koffieganache",
          image: "pralines/koffieboon.jpg"
        },
        {
          naam: "Ronde - geruit",
          beschrijving: "Limoncellocrème\n(bevat alcohol)",
          image: "pralines/rond_geruit.jpg"
        },
        {
          naam: "Diamant",
          beschrijving: "Karamel",
          image: "pralines/diamant.jpg"
        },
        {
          naam: "Cuvette - ovaal",
          beschrijving: "Hazelnootpralinérème\nmet citroen",
          image: "pralines/cuvette_ovaal.jpg"
        },
        {
          naam: "Driehoek",
          beschrijving: "Amandelpralinécrème met honing",
          image: "pralines/driehoek.jpg"
        },
        {
          naam: "Halve bol",
          beschrijving: "Hazelnootlikeurganache (Frangelico)\n(bevat alcohol)",
          image: "pralines/halve_bol.jpg"
        },
        {
          naam: "Rechthoek met kronkel",
          beschrijving: "Banaancrème",
          image: "pralines/slang.jpg"
        },
        {
          naam: "Dopje",
          beschrijving: "Mandarijnganache",
          image: "pralines/dopje.jpg"
        },
        {
          naam: "Palm of french ovaal",
          beschrijving: "Honingganache met kaneel",
          image: "pralines/french_palm.jpg"
        },
        {
          naam: "Dubbel hart",
          beschrijving: "Dulce de leche",
          image: "pralines/dubbelhart.jpg"
        }/**,
        {
          naam: "Karak",
          beschrijving: "Origine chocolade uit El Salvador",
          image: "pralines/karak.jpg"
        }*/
        ],
      assortiment: [
        {
          naam: "",
          beschrijving: "Je hebt de keuze tussen verschillende doosjes met een gemengd assortiment:" +
            "\n" +
            "\n geschenkdoos met 16pralines - 11,50 euro" +
            "\n" +
            "\n 250g - 12,50 euro" +
            "\n 375g - 18,75 euro" +
            "\n 500g - 25,00 euro" +
            "\n" +
            "\nEen doosje bevat een mengeling van de bovenstaande pralines en de pralines."
        },
      ],
    },
  },
  {
    path: '/herfst',
    name: 'Herfst',
    component: PralineListDoos,
    props: {
      titel: "Herfst",
      text: "Allergenen: bevat ei, gluten, hazelnoot, pecannoot, melk en sojalecithine. \nKan sporen bevatten van noten, gluten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "Egel",
          beschrijving: "dulce de leche" +
            "\n hazelnootpraliné met krokant koekje",
          image: "herfst/egel.jpg"
        },
        {
          naam: "3noten",
          beschrijving: "pecannotencrème",
          image: "herfst/3noten.jpg"
        },
        {
          naam: "blad",
          beschrijving: "donkere frambozenganache",
          image: "herfst/blad.jpg"
        },
        {
          naam: "Herfst",
          beschrijving: "Herfstdoosje" +
            "\n" +
            "\n 7,50 euro",
          image: "herfst/herfst1b.jpg"
        }],
      assortiment: [

      ]},
  },
  /**{
    path: '/zomervakantie',
    name: 'Zomervakantie',
    component: PralineListDoos,
    props: {
      titel: "Zon, zee en schelpjes",
      text: "Allergenen: bevat hazelnoot, pecannoot en sojalecithine. \nKan sporen bevatten van andere noten, ei, gluten, melk, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "schelp ",
          beschrijving: "schelp in melkchocolade \nof pure chocolade",
          image: "zomervakantie/schelp1.jpg"
        },
        {
          naam: "zeevruchten - melkchocolade",
          beschrijving: "zeevruchten in melkchocolade" +
            "\nmet pecannootpraliné",
          image: "zomervakantie/zeevruchten_bruin.jpg"
        },
        {
          naam: "zeevruchten - pure chocolade",
          beschrijving: "zeevruchten in pure chocolade" +
            "\nmet hazelnootpraliné",
          image: "zomervakantie/zeevruchten_zwart.jpg"
        },
        {
          naam: "visjes",
          beschrijving: "caraques",
          image: "zomervakantie/visjes.jpg"
        },
        {
          naam: "Verpakking",
          beschrijving: "Gevulde schelp in \nmelkchocolade of pure chocolade \nmet zeevruchten en caraques" +
            "\n" +
            "\n12 euro",
          image: "zomervakantie/schelp_gevuld3.jpg"
        }],
      assortiment: [

      ]},
  },
  {
    path: '/vaderdag',
    name: 'Vaderdag',
    component: PralineListDoos,
    props: {
      titel: "Hartjes",
      text: "Allergenen: bevat pecannoot, melk en sojalecithine. \nKan sporen bevatten van gluten, andere noten, ei, gluten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "Vaderdag",
          beschrijving: "Voor de allerliefste papa!!",
          image: "vaderdag/hart_open1.jpg"
        },
        {
          naam: "melkchocolade hart met witte strepen ",
          beschrijving: "aardbeiganache met gember",
          image: "vaderdag/dubbel_hart_melk_wit.jpg"
        },
        {
          naam: "melkchocolade hart met zwarte strepen ",
          beschrijving: "ganache van origine chocolade" +
          "\nEl Salvador",
          image: "vaderdag/dubbel_hart_melk_puur.jpg"
        },
        {
          naam: "wit chocolade hart met bruine strepen ",
          beschrijving: "pecannootpraliné met honing",
          image: "vaderdag/dubbel_hart_wit_melk.jpg"
        },
        {
          naam: "wit chocolade hart met zwarte strepen",
          beschrijving: "limoncellocrème" +
          "bevat alcohol",
          image: "vaderdag/dubbel_hart_wit_puur.jpg"
        },
        {
          naam: "pure chocolade hart met witte strepen ",
          beschrijving: "koffiekaramel",
          image: "vaderdag/dubbel_hart_puur_wit.jpg"
        },
        {
          naam: "pure chocolade hart met bruine strepen ",
          beschrijving: "ganache met cognac",
          image: "vaderdag/dubbel_hart_puur_melk.jpg"
        },
        {
          naam: "Verpakking",
          beschrijving: "Assortiment hartjes:" +
            "\n" +
            "\ndoorschijnend doosje" +
            "\n10 hartjes: 7,50 euro" +
            "\n" +
            "\ndoosje met hartjes" +
            "\n15 hartjes: 11,00 euro",
          image: "vaderdag/vaderdag1.jpg"
        }],
      assortiment: [

      ]},
  },*/
  /**
  {
    path: '/moederdag_doosje',
    name: 'Moederdag - doosje',
    component: PralineListDoos,
    props: {
      titel: "Moederdag - I love you",
      text: "Allergenen: bevat hazelnoot, ei, gluten, melk, pecannoot en sojalecithine. \nKan sporen bevatten van andere noten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "I love You",
          beschrijving: "",
          image: "moederdag/doosje_inhoud_melk.jpg"
        },
        {
          naam: "I love you",
          beschrijving: "tablet in melkchocolade \nof pure chocolade",
          image: "moederdag/tablet.jpg"
        },
        {
          naam: "diamant",
          beschrijving: "karamel\n",
          image: "moederdag/diamant.jpg"
        },
        {
          naam: "margriet",
          beschrijving: "frambozenganache\n",
          image: "moederdag/margriet.jpg"
        },
        {
          naam: "geruit hart",
          beschrijving: "hazelnootpraliné met feuilletine",
          image: "moederdag/hart_geruit.jpg"
        },
        {
          naam: "ovaal bloempjes",
          beschrijving: "passievruchtkaramel",
          image: "moederdag/ovaal_bloempjes.jpg"
        },
        {
          naam: "dubbel hart",
          beschrijving: "pecannootpralinécrème\n",
          image: "moederdag/dubbelhart.jpg"
        },
        {
          naam: "vierkant bloempjes",
          beschrijving: "lentehoning met theeganache",
          image: "moederdag/vierkant_bloempjes.jpg"
        },
        {
          naam: "cacaoboon",
          beschrijving: "cacaoboon \ndulce de leche",
          image: "moederdag/doosje_inhoud_puur.jpg"
        },
        {
          naam: "klavertje",
          beschrijving: "dulce de leche",
          image: "moederdag/klaver.jpg"
        },
        {
          naam: "hartjes",
          beschrijving: "hartjes in chocolade \n",
          image: "moederdag/hartjes.jpg"
        },
        {
          naam: "Hartjes - pure chocolade",
          beschrijving: "Een doosje bevat een tablet in melkchocolade of pure chocolade en pralines in de verschillende smaken" +
            "\n" +
            "\n11,50 euro per doosje",
          image: "moederdag/doosje.jpg"
        }],
      assortiment: [

      ]},
  },
  {
    path: '/moederdag_hart',
    name: 'Moederdag',
    component: PralineListDoos,
    props: {
      titel: "Moederdag - gevuld hart",
      text: "Allergenen: bevat hazelnoot, ei, gluten, melk, pecannoot en sojalecithine. \nKan sporen bevatten van andere noten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "Gevuld hart",
          beschrijving: "",
          image: "moederdag/hart_boven2.jpg"
        },
        {
          naam: "Groot hart",
          beschrijving: "hart in melkchocolade \nof pure chocolade",
          image: "moederdag/harten.jpg"
        },
        {
          naam: "diamant",
          beschrijving: "karamel\n",
          image: "moederdag/diamant.jpg"
        },
        {
          naam: "margriet",
          beschrijving: "frambozenganache\n",
          image: "moederdag/margriet.jpg"
        },
        {
          naam: "geruit hart",
          beschrijving: "hazelnootpraliné met feuilletine",
          image: "moederdag/hart_geruit.jpg"
        },
        {
          naam: "ovaal bloempjes",
          beschrijving: "passievruchtkaramel",
          image: "moederdag/ovaal_bloempjes.jpg"
        },
        {
          naam: "dubbel hart",
          beschrijving: "pecannootpralinécrème\n",
          image: "moederdag/dubbelhart.jpg"
        },
        {
          naam: "vierkant bloempjes",
          beschrijving: "lentehoning met theeganache",
          image: "moederdag/vierkant_bloempjes.jpg"
        },
        {
          naam: "klavertje",
          beschrijving: "dulce de leche",
          image: "moederdag/klaver.jpg"
        },
        {
          naam: "bloemen",
          beschrijving: "bloemen in chocolade \n",
          image: "moederdag/hart_inhoud.jpg"
        },
        {
          naam: "hartjes",
          beschrijving: "hartjes in chocolade \n",
          image: "moederdag/hartjes.jpg"
        },
        {
          naam: "Hartjes - pure chocolade",
          beschrijving: "Chocolade hart in melkchocolade of pure chocolade gevuld met pralines in de verschillende smaken" +
            "\n" +
            "\n12,50 euro per hart",
          image: "moederdag/hart_zijkant2.jpg"
        }
        ],
      assortiment: [

      ]},
  },
  */
  /**{
    path: '/paasmand',
    name: 'Paasmand',
    component: PralineListDoos,
    props: {
      titel: "Paasmand",
      text: "Allergenen: bevat cashewnoot, hazelnoot, ei, melk en sojalecithine. \nKan sporen bevatten van gluten, andere noten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "Pasen",
          beschrijving: "half ei in\nmelk- of pure chocolade",
          image: "pasen/paasmand_middel_aangepast.jpg"
        },
        {
          naam: "paasdiertjes",
          beschrijving: "mango- passievruchtganache",
          image: "pasen/paasdiertjes.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (groot) - wit",
          beschrijving: "hazelnootpralinécrème" +
            "\nmet honing",
          image: "pasen/gemarmerd_groot_wit.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (groot) - melk",
          beschrijving: "cashewnootpraliné",
          image: "pasen/gemarmerd_groot_bruin.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (groot) - puur",
          beschrijving: "ganache met speculaaskruiden",
          image: "pasen/gemarmerd_groot_zwart.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (klein) - wit",
          beschrijving: "witte chocolade",
          image: "pasen/gemarmerd_klein_wit.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (klein) - melk",
          beschrijving: "melkchocolade",
          image: "pasen/gemarmerd_klein_bruin.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (klein) - puur",
          beschrijving: "pure chocolade",
          image: "pasen/gemarmerd_klein_zwart.jpg"
        },
        {
          naam: "paaseitjes gestreept - wit",
          beschrijving: "framboosganache",
          image: "pasen/gestreept_wit.jpg"
        },
        {
          naam: "paaseitjes gestreept - melk",
          beschrijving: "fondantsuikercrème - vanille",
          image: "pasen/gestreept_bruin.jpg"
        },
        {
          naam: "paaseitjes gestreept - puur",
          beschrijving: "fondantsuikercrème - banaan",
          image: "pasen/gestreept_zwart.jpg"
        },
        {
          naam: "paaseitjes kuikentjes",
          beschrijving: "witte ganache",
          image: "pasen/kuikentje.jpg"
        },
        {
          naam: "paaseitjes waaier - wit",
          beschrijving: "limoenkaramel",
          image: "pasen/waaier_wit.jpg"
        },
        {
          naam: "paaseitjes waaier - melk",
          beschrijving: "karamel",
          image: "pasen/waaier_bruin.jpg"
        },
        {
          naam: "paaseitjes waaier - puur",
          beschrijving: "dulce de leche",
          image: "pasen/waaier_zwart.jpg"
        },
        {
          naam: "paasklokjes",
          beschrijving: "advocaatcrème",
          image: "pasen/klokjes.jpg"
        }
        ],
      assortiment: [
        {
          naam: "",
          beschrijving: "Je hebt de keuze tussen verschillende maten:" +
            "\n" +
            "\n 210g - 10,00 euro" +
            "\n 430g - 20,00 euro" +
            "\n 660g - 30,00 euro" +
            "\n" +
            "\nHet gevulde ei bevat een mengeling van de bovenstaande eitjes."
        },
      ]},
  },
  {
    path: '/paasdoosje',
    name: 'Paasdoosje',
    component: PralineListDoos,
    props: {
      titel: "Paasdoosje",
      text: "Allergenen: bevat melk en sojalecithine. \nKan sporen bevatten van noten, ei, gluten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "Pasen",
          beschrijving: "",
          image: "pasen/paasdoosje2.jpg"
        },
                {
          naam: "paaseieren",
          beschrijving: "wit - melk - puur",
          image: "pasen/paasei.jpg"
        },
        {
          naam: "paasdiertjes",
          beschrijving: "mango- passievruchtganache",
          image: "pasen/paasdiertjes.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (klein) - wit",
          beschrijving: "witte chocolade",
          image: "pasen/gemarmerd_klein_wit.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (klein) - melk",
          beschrijving: "melkchocolade",
          image: "pasen/gemarmerd_klein_bruin.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (klein) - puur",
          beschrijving: "pure chocolade",
          image: "pasen/gemarmerd_klein_zwart.jpg"
        },
        {
          naam: "paaseitjes gestreept - wit",
          beschrijving: "framboosganache",
          image: "pasen/gestreept_wit.jpg"
        },
        {
          naam: "paaseitjes gestreept - melk",
          beschrijving: "fondantsuikercrème - vanille",
          image: "pasen/gestreept_bruin.jpg"
        },
        {
          naam: "paaseitjes gestreept - puur",
          beschrijving: "fondantsuikercrème - banaan",
          image: "pasen/gestreept_zwart.jpg"
        },
        {
          naam: "paaseitjes kuikentjes",
          beschrijving: "witte ganache",
          image: "pasen/kuikentje.jpg"
        },
        {
          naam: "paaseitjes waaier - wit",
          beschrijving: "limoenkaramel",
          image: "pasen/waaier_wit.jpg"
        },
        {
          naam: "paaseitjes waaier - melk",
          beschrijving: "karamel",
          image: "pasen/waaier_bruin.jpg"
        },
        {
          naam: "paaseitjes waaier - puur",
          beschrijving: "dulce de leche",
          image: "pasen/waaier_zwart.jpg"
        }
        ],
      assortiment: [
        {
          naam: "",
          beschrijving: "Het doosje bevat:" +
            "\n" +
            "\n 3 paaseieren" +
            "\n 3 paasdiertjes" +
            "\n 20  gevulde eitjes" +
            "\n" +
            "\n 280g - 12,00 euro"
        },
      ]},
  },
  {
    path: '/paashaasjes',
    name: 'Paashaasjes',
    component: PralineListDoos,
    props: {
      titel: "Paashaasjes",
      text: "Allergenen: bevat amandel, cashewnoot, hazelnoot, ei, gluten, melk en sojalecithine. \nKan sporen bevatten van andere noten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "Pasen",
          beschrijving: "",
          image: "pasen/paashaas_inhoud.jpg"
        },
        {
          naam: "paashaasje - Henry",
          beschrijving: "hazelnootpraliné" +
            "\nmet feuilletine",
          image: "pasen/haasje_henry.jpg"
        },
        {
          naam: "lachende paashaas",
          beschrijving: "amandelcrème met kruiden" +
            "\n(kaneel, gember en kardemom)",
          image: "pasen/haasje_lachend.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (groot) - wit",
          beschrijving: "hazelnootpralinécrème" +
            "\nmet honing",
          image: "pasen/gemarmerd_groot_wit.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (groot) - melk",
          beschrijving: "cashewnootpraliné",
          image: "pasen/gemarmerd_groot_bruin.jpg"
        },
        {
          naam: "paaseitjes gemarmerd (groot) - puur",
          beschrijving: "ganache met speculaaskruiden",
          image: "pasen/gemarmerd_groot_zwart.jpg"
        },
        {
          naam: "paasklokjes",
          beschrijving: "advocaatcrème",
          image: "pasen/klokjes.jpg"
        }
        ],
      assortiment: [
        {
          naam: "",
          beschrijving: "Het pakketje bevat:" +
            "\n" +
            "\n 2 grote haasjes" +
            "\n 2 kleine haasjes" +
            "\n 4 klokjes" +
            "\n 6  gevulde eitjes" +
            "\n" +
            "\n 220g - 12,00 euro"
        },
      ]},
  },
  /**{
    path: '/valentijn1',
    name: 'Hartjes',
    component: PralineListDoos,
    props: {
      titel: "Hartjes",
      text: "Allergenen: bevat cashewnoot, hazelnoot, melk en sojalecithine. \nKan sporen bevatten van ei, gluten, andere noten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "valentijn1",
          beschrijving: "Mijn hart heeft een beslissing genomen en ik denk dat jij het bent.",
          image: "valentijn/valentijnpakket1_org.jpg"
        },
        {
          naam: "Hart - witte chocolade",
          beschrijving: "praliné van cashewnoten",
          image: "valentijn/hart_wit.jpg"
        },
        {
          naam: "Hart - melkchocolade",
          beschrijving: "karamel",
          image: "valentijn/hart_bruin.jpg"
        },
        {
          naam: "Hart - pure chocolade",
          beschrijving: "hazelnootpralinécrème\nmet citroen",
          image: "valentijn/hart_zwart.jpg"
        }
        ],
      assortiment: [
        {
          naam: "",
          beschrijving: "Het zakje bevat 15 hartjes" +
            "\n" +
            "\n11,00 euro per zakje"
        },
      ]},
  },
  {
    path: '/valentijn2',
    name: 'I love you',
    component: PralineListDoos,
    props: {
      titel: "I love you",
      text: "Allergenen: bevat amandel, cashewnoot, melk en sojalecithine. \nKan sporen bevatten van ei, gluten, andere noten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "valentijn2",
          beschrijving: "All you need is love,\nen een beetje chocolade.",
          image: "valentijn/valentijnpakket2.jpg"
        },
        {
          naam: "I love you",
          beschrijving: "tablet in melkchocolade \nof pure chocolade",
          image: "valentijn/tablet.jpg"
        },
        {
          naam: "Cupido - witte chocolade",
          beschrijving: "passievruchtenganache",
          image: "valentijn/cupido_wit.jpg"
        },
        {
          naam: "Cupido - melkchocolade",
          beschrijving: "frambozenganache",
          image: "valentijn/cupido_bruin.jpg"
        },
        {
          naam: "Cupido - pure chocolade",
          beschrijving: "ganache van origine chocolade uit El Salvador",
          image: "valentijn/cupido_zwart.jpg"
        },
        {
          naam: "Hartjes - witte chocolade",
          beschrijving: "amandelpralinécrème \nmet honing",
          image: "valentijn/dubbelhart_wit.jpg"
        },
        {
          naam: "Hartjes - melkchocolade",
          beschrijving: "dulce de leche",
          image: "valentijn/dubbelhart_bruin.jpg"
        },
        {
          naam: "Hartjes - pure chocolade",
          beschrijving: "cashewnootpralinécrème",
          image: "valentijn/dubbelhart_zwart.jpg"
        }
        ],
      assortiment: [
        {
          naam: "",
          beschrijving: "Een doosje bevat een tablet in melkchocolade of pure chocolade en 2x6 pralines in de verschillende smaken" +
            "\n" +
            "\n9,00 euro per doosje"
        },
      ]},
  },
  {
    path: '/valentijn3',
    name: 'Valentijn pralines',
    component: PralineListDoos,
    props: {
      titel: "Valentijn pralines",
      text: "Allergenen: bevat amandel, cashewnoot, hazelnoot, melk en sojalecithine. \nKan sporen bevatten van ei, gluten, andere noten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "Hart - witte chocolade",
          beschrijving: "Alle liefde is zoet, \ngegeven of ontvangen.",
          image: "valentijn/valentijnpakket3.jpg"
        },
        {
          naam: "Hart - witte chocolade",
          beschrijving: "praliné van cashewnoten",
          image: "valentijn/hart_wit2.jpg"
        },
        {
          naam: "Hart - melkchocolade",
          beschrijving: "karamel",
          image: "valentijn/hart_bruin2.jpg"
        },
        {
          naam: "Hart - pure chocolade",
          beschrijving: "hazelnootpralinécrème\nmet citroen",
          image: "valentijn/hart_zwart2.jpg"
        },
        {
          naam: "Cupido - witte chocolade",
          beschrijving: "passievruchtenganache",
          image: "valentijn/cupido_wit.jpg"
        },
        {
          naam: "Cupido - melkchocolade",
          beschrijving: "frambozenganache",
          image: "valentijn/cupido_bruin.jpg"
        },
        {
          naam: "Cupido - pure chocolade",
          beschrijving: "ganache van origine chocolade uit El Salvador",
          image: "valentijn/cupido_zwart.jpg"
        },
        {
          naam: "Hartjes - witte chocolade",
          beschrijving: "amandelpralinécrème \nmet honing",
          image: "valentijn/dubbelhart_wit.jpg"
        },
        {
          naam: "Hartjes - melkchocolade",
          beschrijving: "dulce de leche",
          image: "valentijn/dubbelhart_bruin.jpg"
        },
        {
          naam: "Hartjes - pure chocolade",
          beschrijving: "cashewnootpralinécrème",
          image: "valentijn/dubbelhart_zwart.jpg"
        },
        {
          naam: "Hart",
          beschrijving: "gekarameliseerde banaan",
          image: "valentijn/hart_geruit.jpg"
        },
        {
          naam: "Hart",
          beschrijving: "honingganache met kaneel",
          image: "valentijn/hart_bol.jpg"
        }
        ],
      assortiment: [
        {
          naam: "",
          beschrijving: "Het doosje bevat een mengeling van de verschillende pralines." +
            "\n" +
            "\n11,00 euro per doosje"
        },
      ]},
  },
  {
    path: '/beesten',
    name: 'Beestige beestenboel',
    component: PralineListDoos,
    props: {
      titel: "Beestige beestenboel",
      text: "Allergenen: bevat melk en sojalecithine. \nKan sporen bevatten van ei, gluten, noten, pinda en sesam." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "Beestige beestenboel",
          beschrijving: "ganache van witte chocolade",
          image: "beesten/beesten.jpg"
        },
        ],
      assortiment: [
        {
          naam: "",
          beschrijving: "De figuurtjes zijn per 6 verpakt, een combinatie van witte chocolade, melk en puur." +
            "\n" +
            "\n5,00 euro per pakje"
        },
      ]},
  },
  {
    path: '/sint23p1',
    name: 'sint1',
    component: PralineListDoos,
    props: {
      titel: "Sintpakket 1",
      text: 'Allergenen: amandel, hazelnoot, melk en sojalecithine. \nKan sporen bevatten van andere noten.',
      pralines: [
        {
          naam: "Sint",
          beschrijving: "Hazelnootpralinécrème met speculaaskruiden",
          image: ".images_sint/sint.jpg"
        },
        {
          naam: "Sintje & zwarte piet",
          beschrijving: "Honingamandelpraliné",
          image: ".images_sint/sint&piet.jpg"
        },
        {
          naam: "Diertjes: poes & aap",
          beschrijving: "Fonfantsuikercrème - vanille",
          image: ".images_sint/aap&poes.jpg"
        },
        {
          naam: "Diertjes: eend & konijn",
          beschrijving: "Fonfantsuikercrème - mandarijn & passievrucht",
          image: ".images_sint/eend&konijn.jpg"
        },
        {
          naam: "Diertjes: olifant & beer",
          beschrijving: "Fonfantsuikercrème - banaan",
          image: ".images_sint/beer&olifant.jpg"
        },
        {
          naam: "Grote diertjes",
          beschrijving: "Witte ganache",
          image: ".images_sint/dier.jpg"
        },
        {
          naam: "Enveloppe",
          beschrijving: "Hazelnootlikeurganache",
          image: ".images_sint/enveloppe.jpg"
        },
        {
          naam: "Vierkantjes",
          beschrijving: "Karamel",
          image: ".images_sint/vierkantje.jpg"
        },
        {
          naam: "Halve bol",
          beschrijving: "Mandarijnkaramel met Grand Marnier Jaune",
          image: ".images_sint/bolletje.jpg"
        }
      ],
      assortiment: []
    },
  },
  {
    path: '/sint23p2',
    name: 'sint2',
    component: PralineListDoos,
    props: {
      titel: "Sintpakket 2",
      text: "Allergenen: amandel, hazelnoot, melk en sojalecithine. \nKan sporen bevatten van andere noten.",
      pralines: [
        {
          naam: "Sint",
          beschrijving: "Hazelnootpralinécrème met speculaaskruiden",
          image: ".images_sint/sint.jpg"
        },
        {
          naam: "Sintje & zwarte piet",
          beschrijving: "Honingamandelpraliné",
          image: ".images_sint/sint&piet.jpg"
        },
        {
          naam: "Diertjes: poes & aap",
          beschrijving: "Fonfantsuikercrème - vanille",
          image: ".images_sint/aap&poes.jpg"
        },
        {
          naam: "Diertjes: eend & konijn",
          beschrijving: "Fonfantsuikercrème - mandarijn & passievrucht",
          image: ".images_sint/eend&konijn.jpg"
        },
        {
          naam: "Diertjes: olifant & beer",
          beschrijving: "Fonfantsuikercrème - banaan",
          image: ".images_sint/beer&olifant.jpg"
        },
        {
          naam: "Grote diertjes",
          beschrijving: "Witte ganache",
          image: ".images_sint/dier.jpg"
        }
      ],
      assortiment: [],
    },
  },
  */
  /**{
    path: '/feestpralines',
    name: 'Feestpralines',
    component: PralineListDoos,
    props: {
      titel: "Feestpralines",
      text: "Allergenen: amandel, cashewnoot, hazelnoot, ei, melk en sojalecithine. \nKan sporen bevatten van gluten, andere noten, sesam en pinda." +
        "\nMeer info of vragen, stel ze gerust via mail.",
      pralines: [
        {
          naam: "Vierkant met bubbels",
          beschrijving: "Limoenkaramel met vanille",
          image: "eindejaar/vierkant-bubbels.jpg"
        },
        {
          naam: "Rechthoek met dubbele streep of enveloppe",
          beschrijving: "Amandelpraliné",
          image: "eindejaar/enveloppe.jpg"
        },
        {
          naam: "Gestreept vierkant",
          beschrijving: "Chouffe cream karamel",
          image: "eindejaar/vierkant-gestreept.jpg"
        },
        {
          naam: "Margriet",
          beschrijving: "Framboosganache",
          image: "eindejaar/margriet.jpg"
        },
        {
          naam: "Dubbel hart",
          beschrijving: "Passievruchtganache",
          image: "eindejaar/dubbel-hart.jpg"
        },
        {
          naam: "Koffieboon",
          beschrijving: "Koffieganache",
          image: "eindejaar/koffieboon.jpg"
        },
        {
          naam: "Driehoek",
          beschrijving: "Advocaatcrème",
          image: "eindejaar/ananas.jpg"
        },
        {
          naam: "Hoefijzer of halve bol",
          beschrijving: "Limoncellocrème",
          image: "eindejaar/hoefijzer-bol.jpg"
        },
        {
          naam: "Cacaoboon",
          beschrijving: "Ganache van origine chocolade\n(DR Congo)",
          image: "eindejaar/cacaoboon.jpg"
        },
        {
          naam: "Gestreept rondje of ovaal",
          beschrijving: "Cashewnootpraliné",
          image: "eindejaar/ovaal-dopje.jpg"
        },
        {
          naam: "Rudolf",
          beschrijving: "Fondantcrème - banaan",
          image: "eindejaar/eland.jpg"
        },
        {
          naam: "Sneeuwman",
          beschrijving: "Pure chocoladeganache\nmet stukjes hazelnoot",
          image: "eindejaar/sneeuwman.jpg"
        },
        {
          naam: "Cuvette - ovaal",
          beschrijving: "Hazelnootpralinérème\nmet citroen",
          image: "eindejaar/cuvette-ovaal.jpg"
        },
        ],
      assortiment: [
                {
          naam: "",
          beschrijving: "Niet meer verkrijgbaar." +
            "\n Andere pralines wel nog verkrijgbaar via het menu onder de tab pralines."
            // + "Je hebt de keuze tussen verschillende doosjes:" +
            //"\n" +
            //"\n 250g - 11,00 euro" +
            //"\n 375g - 16,50 euro" +
            //"\n 500g - 22,00 euro" +
            //"\n" +
            //"\nDe doosjes zijn verkrijgbaar in pure chocolade, melkchocolade en een mengeling van witte, melkchocolade en pure chocolade."
        },
      ]},
  },*/
  {
    path: '/verstuurd',
    name: 'register_done',
    component: RegisterDone
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

function strip(path) {
  let last = path.slice(-1, path.length)
  if (last === '/')
    return path.slice(0, -1);
  return path;
}

router.beforeEach(to => {
  // Links mogen niet eindigen op een nummer (bv ../../../1)
  let path = strip(to.fullPath)
  let split = path.split("/")
  if(!isNaN(+split[split.length-1])){
    path = split.slice(0, -1).join("/")
  }
  if (!routes.map(el => strip(el.path.split(":")[0])).includes(path)) {
    console.log('Non existing url')
    // trigger a redirection
    return '/'
  }
})

export default router
